

















































.t-col-1 {
  width: 10%;
}
.t-col-2 {
  // width: 40%;
  width: 60%;
}
.t-col-3 {
  // width: 40%;
  width: 30%;
}
.t-col-4 {
  width: 10%;
}
